
import { api } from "@/api/api";
import { ApiGetCostDto, ApiGetCostTypeDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { AuthRole } from "@/auth/auth.constants";
import { authService } from "@/auth/authService";
import ExpensesModal from "@/components/administration/expenses/ExpensesModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { VSwitch } from "vuetify/lib";
import { ExpenseType } from "@/shared/enums/expenseType.enum";

export default defineComponent({
  name: "ExpensesPage",
  components: {
    BaseTableFiltered,
    BaseModal,
    ExpensesModal,
    BaseLayout,
  },
  setup() {
    const costTypes = ref<ApiGetCostTypeDto[]>([]);
    const costList = ref<ApiGetCostDto[]>([]);
    const search = ref("");
    const dataReady = ref<boolean>(false);

    const modalData = ref<ModalBaseData>({
      modalHeadline: "",
      modalType: ModalType.Unspecified,
      showModal: false,
      existingItem: undefined,
      existingItemId: undefined,
    });

    const openNewExpense = useOpenModal(ModalType.Add, "utgift", modalData);
    const displayExpense = useOpenModal(ModalType.Display, "utgift", modalData);
    const editExpense = useOpenModal(ModalType.Edit, "utgift", modalData);

    const closeAndLoad = () => {
      modalData.value.showModal = false;
      loadCosts();
    };

    const loadCosts = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const response = await api.economy.getCostsPerOrganizationAsync({
          CostTypeCostGroup: ExpenseType.Expenses,
        });
        costList.value = response.data;
      });
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const response = await api.economy.getCostTypesAsync();
        costTypes.value = response.data;
      });
      await loadCosts();
      dataReady.value = true;
    });

    return {
      filter: filter(),
      costList,
      search,
      modalData,
      openNewExpense,
      displayExpense,
      editExpense,
      costTypes,
      ExpenseType,
      dataReady,
      costHeaders,
      loadCosts,
      closeAndLoad,
      getExpenseTypeById,
      canUpsert: computed(() => authService.hasRoles(AuthRole.ModifyCost)),
    };
  },
  methods: {},
});

const getExpenseTypeById = (id: number, costTypes: ApiGetCostTypeDto[]) =>
  costTypes.find((current) => current.id === id)?.costGroup ?? `Ukjent (${id})`;

const filter = () => [
  {
    component: VSwitch,
    value: "isActive",
    staticClass: "mx-3",
    default: true,
    attrs: {
      label: "Vis inaktive",
      inset: true,
    },
    apply: (value: any, model: any) => model || value,
  },
];
const costHeaders = [
  { text: "Navn", value: "name" },
  { text: "Handlinger", value: "actions" },
  { text: "Kategori", value: "costTypeId" },
  { text: "Artikkelnummer", value: "articleNo" },
  { text: "Undervisningsform", value: "teachingMethod" },
  { text: "Sats", value: "price" },
  { text: "Status", value: "isActive" },
];
